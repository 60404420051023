import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86')
];

export const server_loads = [0,10,11,12,13];

export const dictionary = {
		"/(home)": [~19],
		"/about-us": [~29],
		"/ama": [30],
		"/authors/[uid]": [~32],
		"/auth/callback": [31],
		"/(screeners)/best-singapore-dividend-stocks": [~20,[5]],
		"/(screeners)/best-singapore-high-dividend-stocks": [~21,[5]],
		"/(screeners)/best-singapore-reits": [~22,[5]],
		"/contact": [33],
		"/disclaimer": [~34],
		"/editorial-guidelines": [35],
		"/embed/cash-mgmt-yield": [~36,[7]],
		"/embed/compare-yield": [~37,[7]],
		"/embed/dividend-calculator/[ticker]": [~38,[7]],
		"/embed/money-habits-tracker": [39,[7]],
		"/embed/price-to-book": [~40,[7]],
		"/embed/reit-yields": [~41,[7]],
		"/embed/savings-account-calculator": [~42,[7]],
		"/embed/srs-tax-savings-calculator": [43,[7]],
		"/embed/ssb-swap-calculator": [~44,[7]],
		"/embed/tbill-calendar": [~45,[7]],
		"/etfs": [~46,[8]],
		"/etfs/all": [~48,[8]],
		"/etfs/themes/[theme]": [~49],
		"/etfs/[ticker]": [~47,[8]],
		"/events": [~50],
		"/(unauthenticated)/forgot-password": [24,[6]],
		"/glossary": [~51],
		"/(catalog)/insights/[[theme]]": [~17,[4]],
		"/learn-and-grow/[category]": [~52],
		"/(article)/lessons/[slug]": [~15,[2]],
		"/(unauthenticated)/login": [25,[6]],
		"/my": [~53],
		"/newsletter-signup": [55],
		"/newsletter": [~54],
		"/partners": [~56],
		"/privacy": [~57],
		"/promo": [~58],
		"/promo/expired": [~61],
		"/promo/[slug]": [~59,[10]],
		"/promo/[slug]/thank-you": [60,[10]],
		"/quote/[ticker]": [62,[11]],
		"/quote/[ticker]/dividend": [~63,[11]],
		"/reits": [64,[12]],
		"/reits/all": [~65],
		"/reits/compare": [~66],
		"/reits/sectors": [67,[12]],
		"/reits/sectors/[theme]": [~68],
		"/(unauthenticated)/reset-password/[email]": [26,[6]],
		"/(catalog)/reviews/[[reviewType]]": [~18,[4]],
		"/search": [69],
		"/securities/[[type=securityType]]": [~70],
		"/(unauthenticated)/signup": [27],
		"/terms-of-use": [~71],
		"/tools": [~72],
		"/tools/best-brokerage-accounts": [~73],
		"/tools/best-savings-accounts": [~74],
		"/tools/compare-yield": [~75,[13]],
		"/tools/compare-yield/cash-mgt": [~76,[13]],
		"/tools/compare-yield/fixed-deposit": [~77,[13]],
		"/tools/compare-yield/ssb": [~78,[13]],
		"/tools/compare-yield/tbill": [~79,[13]],
		"/tools/find-savings-account": [80],
		"/tools/find-savings-account/hang-tight": [81],
		"/tools/passive-income-calculator": [~82],
		"/tools/srs-tax-savings-calculator": [83],
		"/tools/ssb-interest-rate-projection": [~84],
		"/tools/ssb-swap-calculator": [~85],
		"/tools/tbill-calculator": [~86],
		"/(screeners)/upcoming-dividends": [~23,[5]],
		"/(unauthenticated)/verify/[payload]": [28,[6]],
		"/(authenticated)/watchlist": [16,[3]],
		"/(article)/[...slug]": [~14,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';