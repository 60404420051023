import { goto } from '$app/navigation';
import { env } from '$env/dynamic/public';
import { getWatchlist } from '$lib/utils/auth';
import * as Sentry from '@sentry/sveltekit';
import { Amplify } from 'aws-amplify';
import 'aws-amplify/auth/enable-oauth-listener';
import { Hub } from 'aws-amplify/utils';

Sentry.init({
	dsn: env.PUBLIC_SENTRY_DSN,
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	ignoreErrors: [
		// Random plugins/extensions
		'Non-Error promise rejection',
		// Chrome extensions
		'chrome-extension://',
		// Firefox extensions
		'moz-extension://',
		// Safari extensions
		'safari-extension://'
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolId: env.PUBLIC_COGNITO_USER_POOL_ID,
			userPoolClientId: env.PUBLIC_COGNITO_CLIENT_ID,
			loginWith: {
				oauth: {
					domain: env.PUBLIC_COGNITO_AUTH_DOMAIN,
					scopes: ['email', 'openid', 'profile'],
					redirectSignIn: [`${window.location.origin}/auth/callback`],
					redirectSignOut: [`${window.location.origin}/auth/callback`],
					responseType: 'code'
				}
			}
		}
	}
});

let state = '';

Hub.listen('auth', async ({ payload }) => {
	switch (payload.event) {
		case 'customOAuthState':
			state = payload.data;
			break;

		case 'signedIn': {
			const watchlist = await getWatchlist();
			if (!watchlist?.symbols?.length && !state.length) {
				await goto('/watchlist');
				return;
			}
			const url = state.length ? state : '/';
			state = '';
			await goto(url, {
				replaceState: true
			});
			break;
		}

		case 'signInWithRedirect_failure':
			throw new Error(payload.data.error?.message);
	}
});

export const ssr = false;
